let BASE_URL;
switch(process.env.NODE_ENV){
    case 'development':
        BASE_URL = '/api' //本地环境,代理
        break
    case 'test':
        BASE_URL = 'https://m.yssn.cn' //测试环境
        break
    case 'production':
        BASE_URL = 'https://m.yssn.cn' //生产环境
        break
}

export default BASE_URL;