
import axios from "axios";
import { Toast } from 'vant';
import BASE_URL from '../utils/base_url';

axios.defaults.timeout = 5000
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8"
// axios.defaults.baseURL = '/api'; //配置接口地址
axios.defaults.baseURL = BASE_URL //配置接口地址

/****** request拦截器==>对请求参数做处理 ******/
axios.interceptors.request.use(
  (config) => {
    // config.header.token = '000'
    return config;
  },
  (error) => {
    //请求错误处理
    console.log(error);
    Promise.reject(error);
  }
);

/****** respone拦截器==>对响应做处理 ******/
axios.interceptors.response.use(
  (response) => {
    //成功请求到数据
    if(response.data.code !== 0){
      Toast.fail(response.data.message);
    }
    console.log('请求结果：',response.data);
    return response.data;
  },
  (error) => {
    console.log('请求失败：',error);
    Toast.fail(error.data.message);
    return Promise.reject(error);
  }
);

//返回一个Promise(发送post请求)
export function axiosPost(url, params) {
	return new Promise((resolve, reject) => {
		axios.post(url, params)
			.then(response => {
				resolve(response);
			}, err => {
				reject(err);
			})
			.catch((error) => {
				reject(error)
			})
	})
}

// 返回一个Promise(发送get请求)
export function axiosGet(url, param) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
				params: param
			})
			.then(response => {
				resolve(response)
			}, err => {
				reject(err)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export default {
	axiosPost,
	axiosGet
}
